import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { forkJoin, map, of } from 'rxjs';

import { AuthService } from '@lysties/auth/data-access';
import { themeResolver } from '@lysties/shared/theming';

export const appResolver: ResolveFn<unknown[]> = (route, state) => {
  const auth = inject(AuthService);
  const observables = [
    auth.isAuthenticated(true) ? auth.loadUser() : of(null),
    of(themeResolver(route, state)),
  ];
  const document = inject(DOCUMENT);
  const platform = inject(PLATFORM_ID);

  return forkJoin(observables).pipe(
    map((results) => {
      if (isPlatformBrowser(platform)) {
        document.getElementById('loader')?.classList.add('hidden');
      }
      return results;
    }),
  );
};
